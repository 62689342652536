



















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { readProject, readCoworkers, readUserProfile } from '@/store/main/getters';
import { dispatchGetProject, dispatchGetCoworkers, dispatchInviteUserToProject,
	dispatchEditProject, dispatchDeleteProject } from '@/store/main/actions';
import { IProjectUpdate } from '@/interfaces';
import { getUserFullDisplayName } from '@/utils';
import Models from '@/views/main/project/Models.vue';
import Datasets from '@/views/main/project/Datasets.vue';
import FeedbackList from '@/views/main/project/FeedbackList.vue';
import { Role } from '@/enums';

@Component({
	components: {
		Models, Datasets, FeedbackList
	}
})
export default class Project extends Vue {

	@Prop({ required: true }) id!: number;

	userToInvite = "";
	openShareDialog = false;
	openEditDialog = false;
	openDeleteDialog = false;
	newName = "";
	newDescription = "";

	showInspector = false;
	tempInspectorQuery = {}

	public async mounted() {
		// make sure project is loaded first
		await dispatchGetProject(this.$store, {id: this.id});
    await dispatchGetCoworkers(this.$store);
	
		this.setInspector(this.$router.currentRoute);
	}

	@Watch("$route", { deep: true})
	setInspector(to) {
		if (to.path.endsWith('inspect')) {
			this.showInspector = true
			this.tempInspectorQuery = to.query
		}
	}

	get userProfile() {
		return readUserProfile(this.$store);
	}

	get coworkerNamesAvailable() {
		return readCoworkers(this.$store)
			// remove users already in guest list
			// .filter(e => !this.project?.guest_list.map(i => i.user_id).includes(e.user_id));
	}
	
	get project() {
		return readProject(this.$store)(this.id)
	}

	get isProjectOwnerOrAdmin() {
		return this.isUserProjectOwner || this.userProfile?.role.id == Role.ADMIN
	}

	get isUserProjectOwner() {
		return this.project && this.userProfile? this.project.owner_id == this.userProfile.id : false;
	}

	private getUserFullDisplayName = getUserFullDisplayName

	public async inviteUser() {
		if (this.project && this.userToInvite) {
			try {
				await dispatchInviteUserToProject(this.$store, {projectId: this.project.id, userId: this.userToInvite})
				this.userToInvite = ""
				this.openShareDialog = false
			}	catch (e) {
				console.error(e)
			}
		}
	}

	public async submitEditProject() {
		if (this.project && this.newName) {
			const proj: IProjectUpdate = {
				name: this.newName,
				description: this.newDescription,
			}
			try {
				await dispatchEditProject(this.$store, {id: this.project.id, data: proj})
				this.openEditDialog = false;
			} catch (e) {
				console.error(e.message);
			}
		}
  }

  public async deleteProject() {
		if (this.project) {
			try {
				await dispatchDeleteProject(this.$store, {id: this.project.id})
				this.$router.push('/main/dashboard');
			} catch (e) {
				console.error(e.message);
			}
		}
  }

}
